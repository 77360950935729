import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
// components
import { Button, Spinner } from "reactstrap";
import SubscriptionCard from "./SubscriptionCard";
// store
import { ICompany } from "module/main/store/Company/interfaces";
import { getCompanyData } from "module/main/store/Company/selectors";
import { IPlan, PlanIds } from "module/main/store/SubscriptionStore/interfaces";
// utils
import { formatCurrency } from "module/common/helpers/utils";
import { usePlanChoices } from "./hooks";

interface Props {
  setFormData: (data: any) => void;
  advanceToNextStep: () => void;
  isNewSignUp: boolean;
  isSkipTraceSignUp?: boolean;
}

const SubscriptionOptions: React.FC<Props> = ({
  setFormData,
  advanceToNextStep,
  isNewSignUp,
  isSkipTraceSignUp,
}) => {
  const company: ICompany = useSelector(getCompanyData);
  const [subscriptionOptions, isPlanLoading] = usePlanChoices(company.id);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<IPlan>(
    company.plan
  );

  const activePlanId = company.plan?.id;
  const selectedPlanId = selectedSubscriptionPlan.id;

  const shouldDisableContinue = () => {
    if (isNewSignUp)
      return activePlanId !== PlanIds.Free && selectedPlanId === activePlanId;

    return selectedPlanId === activePlanId;
  };

  const SkipTraceOnlyOption = useMemo(() => {
    if (isPlanLoading) return null;
    const stPlan = {
      // Free plan with different description
      ...subscriptionOptions.estimates[0].plan,
      id: PlanIds.SkipTrace,
      description: "Skip Trace",
    };

    const features = [
      {
        class: "",
        content: <span>No Subscription Needed!</span>,
      },
      {
        class: "d-flex",
        content: (
          <div>
            <span>
              {formatCurrency(stPlan.maxPlanPricePropertyOwnerLookup)} to{" "}
              {formatCurrency(stPlan.minUnitPriceSkiptrace)}
            </span>{" "}
            per Skip Trace billable record based on volume
          </div>
        ),
      },
      {
        class: "d-flex",
        content: (
          <div>
            <span>Higher Volume?</span> Talk to <a href="mailto:sales@leadsherpa.com">Sales</a>
          </div>
        ),
      },
    ];

    return {
      plan: stPlan,
      features,
    };
  }, [isPlanLoading]);

  useEffect(() => {
    if (isSkipTraceSignUp && !isPlanLoading)
      setSelectedSubscriptionPlan(SkipTraceOnlyOption!.plan);
  }, [isPlanLoading, isSkipTraceSignUp]);

  if (isPlanLoading) {
    return <Spinner color="primary" size="lg" />;
  }

  const onContinue = () => {
    const id =
      selectedSubscriptionPlan.id === PlanIds.SkipTrace
        ? PlanIds.Free
        : selectedSubscriptionPlan.id;
    setFormData({
      subscription: { ...selectedSubscriptionPlan, id },
      skipTraceOnlyFeatures:
        selectedSubscriptionPlan.id === PlanIds.SkipTrace
          ? SkipTraceOnlyOption!.features
          : null,
    });
    advanceToNextStep();
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap justify-content-center" data-test="styled-card">
        {isSkipTraceSignUp && SkipTraceOnlyOption ? (
          <SubscriptionCard
            onSelectedSubscription={setSelectedSubscriptionPlan}
            plan={SkipTraceOnlyOption.plan}
            isCurrent={selectedPlanId === SkipTraceOnlyOption.plan.id}
            isActive={!isNewSignUp && SkipTraceOnlyOption.plan.id === activePlanId}
            isDisabled={!isNewSignUp && SkipTraceOnlyOption.plan.id !== activePlanId}
            featuresOverride={SkipTraceOnlyOption.features}
          />
        ) : null}
        {subscriptionOptions.estimates.map((estimate) => {
          const plan = estimate.plan;
          const isSelected = selectedPlanId === plan.id;
          return (
            <Fragment key={plan.id}>
              <SubscriptionCard
                onSelectedSubscription={setSelectedSubscriptionPlan}
                plan={plan}
                isCurrent={isSelected}
                isActive={!isNewSignUp && plan.id === activePlanId}
                isDisabled={!isNewSignUp && estimate.changeType !== "upgrade"}
              />
            </Fragment>
          );
        })}
      </div>
      <p className="text-muted align-self-center mt-4">
        *This does not include charges incurred with your service provider.
      </p>
      <p className="text-muted align-self-center">
        **Tax may be included depending on your payment method region.
      </p>
      <Button
        color="primary"
        className="mt-4 mr-2 ml-auto"
        onClick={onContinue}
        disabled={shouldDisableContinue()}
      >
        Continue
      </Button>
    </div>
  );
};

export default SubscriptionOptions;
